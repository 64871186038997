var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "section",
    { staticClass: "OmnisearchComponent m-2" },
    [
      _c("ui-text-input", {
        directives: [
          {
            name: "click-outside",
            rawName: "v-click-outside",
            value: _vm.closeList,
            expression: "closeList",
          },
        ],
        attrs: {
          value: _vm.textInput,
          "icon-left": _vm.ICONS.search,
          name: "query",
          placeholder: "Search Backoffice",
        },
        on: {
          click: _vm.openList,
          focus: _vm.openList,
          keyup: function ($event) {
            if (
              !$event.type.indexOf("key") &&
              _vm._k($event.keyCode, "esc", 27, $event.key, ["Esc", "Escape"])
            )
              return null
            return _vm.closeList.apply(null, arguments)
          },
          changevalue: (event) => (_vm.textInput = event.detail),
        },
      }),
      _c(
        "ul",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.isOpen,
              expression: "isOpen",
            },
          ],
          staticClass:
            "OmnisearchComponent__list mt-4 position-absolute emobg-background-color-white shadow-m emobg-border-radius-small overflow-auto",
          on: { mouseleave: _vm.closeList },
        },
        [
          _vm.hasResults
            ? [
                _vm._l(_vm.config, function (specification) {
                  return _vm._l(
                    _vm.$data[specification.algolia.index],
                    function (result, index) {
                      return _c(
                        "li",
                        {
                          key: `${specification.algolia.index}-${index}`,
                          staticClass:
                            "cursor-pointer emobg-background-color-ground-lightest-hover emobg-focus-background-color-ground-lightest p-1",
                        },
                        [
                          _c(
                            "a",
                            {
                              staticClass:
                                "d-flex align-items-center text-decoration-none emobg-color-inherit",
                              attrs: {
                                href: _vm.getHref({ specification, result }),
                              },
                              on: {
                                click: function ($event) {
                                  return _vm.navigate(
                                    specification,
                                    result,
                                    $event
                                  )
                                },
                              },
                            },
                            [
                              _c(
                                "article",
                                {
                                  staticClass:
                                    "emobg-font-weight-semibold d-inline-flex align-items-center pill emobg-background-color-ground-lighter px-2 py-1",
                                },
                                [
                                  _c("ui-icon", {
                                    staticClass: "emobg-font-weight-semibold",
                                    attrs: {
                                      icon: specification.badge.icon,
                                      size: _vm.ICONS_SIZES.small,
                                    },
                                  }),
                                  _c("p", { staticClass: "m-1" }, [
                                    _vm._v(
                                      " " +
                                        _vm._s(specification.badge.text) +
                                        " "
                                    ),
                                  ]),
                                ],
                                1
                              ),
                              _c(
                                "article",
                                {
                                  staticClass: "px-2 ellipsis",
                                  class: _vm.applyResultByPath({
                                    result,
                                    specification,
                                    path: "primary.class",
                                  }),
                                },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        specification.primary.text(result)
                                      ) +
                                      " "
                                  ),
                                ]
                              ),
                              specification.secondary
                                ? _c(
                                    "article",
                                    {
                                      staticClass: "px-2 ellipsis",
                                      class: _vm.applyResultByPath({
                                        result,
                                        specification,
                                        path: "secondary.class",
                                      }),
                                    },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            specification.secondary.text(result)
                                          ) +
                                          " "
                                      ),
                                    ]
                                  )
                                : _vm._e(),
                            ]
                          ),
                        ]
                      )
                    }
                  )
                }),
              ]
            : [
                _c(
                  "li",
                  {
                    staticClass:
                      "emobg-background-color-ground-lightest-hover emobg-focus-background-color-ground-lightest px-2 py-3",
                  },
                  [_vm._v(" No results ")]
                ),
              ],
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }