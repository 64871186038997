<script>
import each from 'lodash/each';
import get from 'lodash/get';
import has from 'lodash/has';
import isFunction from 'lodash/isFunction';
import map from 'lodash/map';
import max from 'lodash/max';
import reduce from 'lodash/reduce';
import size from 'lodash/size';
import { mapGetters } from 'vuex';
import { ClickOutside } from '@emobg/vue-base';
import { LOG_TYPE, logger, navigationErrorHandler } from '@emobg/web-utils';

import DOMAINS_MODEL from '@domains/DOMAINS_MODEL';
import ALGOLIA_INDEXES from '@/constants/algoliaIndexes';
import { createOperatorChildrenFilter } from '@/utils';

export const createLists = config => reduce(
  config,
  (accumulator, specification) => {
    accumulator[specification.algolia.index] = [];
    return accumulator;
  },
  {},
);

export default {
  name: 'OmnisearchComponent',
  directives: { ClickOutside },
  inheritAttrs: false,
  props: {
    query: {
      type: String,
      default: '',
    },
    activeOperatorId: {
      type: Number,
      default: undefined,
    },
    activeOperatorUuid: {
      type: String,
      default: undefined,
    },
    isActiveOperatorChild: {
      type: Boolean,
      default: null,
    },
    config: {
      type: Array,
      default: () => [],
    },
    operatorChildren: {
      type: Array,
      default: () => [],
    },
    isHrefOnly: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      isOpen: false,
      hasResults: false,
      textInput: this.query,
      ...createLists(this.config),
    };
  },
  computed: {
    ...mapGetters(DOMAINS_MODEL.app.userAccount, ['getOperatorFilter']),
  },
  created() {
    this.$watch(
      vm => [
        vm.textInput,
        vm.activeOperatorId,
        vm.activeOperatorUuid,
      ],
      ([query]) => each(this.config, async specification => {
        const { algolia } = specification;
        const { attribute, index } = algolia;
        const filters = this.getCustomFilters(index, attribute);
        const options = { query, filters, hitsPerPage: 3 };
        const { hits } = await this.$algolia.fetchIndex(index, options);
        this[index] = hits;
        this.getResultsCount();
      }),
      { immediate: true },
    );
  },
  methods: {
    getCustomFilters(index, attribute) {
      if (index === ALGOLIA_INDEXES.reports) {
        return undefined;
      }
      if (index === ALGOLIA_INDEXES.caseEvents) {
        return this.getOperatorFilter({ attribute: 'cs_operator_id' });
      }
      if (index === ALGOLIA_INDEXES.drivingLicenses) {
        return `${this.getOperatorFilter({ attribute: 'cs_operator_fk' })} AND active:true`;
      }
      if (index === ALGOLIA_INDEXES.vehicleDamages) {
        return this.getOperatorFilter({ attribute: 'vehicle.cs_operator_fk' });
      }

      return this.getFilters({ attribute, index });
    },
    getFilters({ attribute, index }) {
      const {
        activeOperatorId,
        activeOperatorUuid,
        isActiveOperatorChild,
        operatorChildren,
      } = this;
      const filters = createOperatorChildrenFilter({
        ALGOLIA_INDEXES,
        activeOperatorId,
        activeOperatorUuid,
        attribute,
        index,
        isActiveOperatorChild,
        operatorChildren,
      });

      return filters;
    },
    getHref({ specification, result }) {
      const routeGetter = get(specification, 'primary.to');
      if (routeGetter) {
        const { href } = this.$router.resolve(routeGetter(result));
        return href;
      }
      const hrefGetter = get(specification, 'primary.href', value => value);
      return hrefGetter(result);
    },
    navigate(specification, result, event) {
      try {
        const isSpa = !this.isHrefOnly && has(specification, 'primary.to');
        if (isSpa) {
          if (event.metaKey || event.shiftKey) {
            // User wants to open link in new tab or window. Anchor's native behaviour is executed
            return;
          }
          event.preventDefault();
          this.$router.push(this.getHref({ specification, result })).catch(navigationErrorHandler);
          return;
        }
      } catch (error) {
        logger.message(error, LOG_TYPE.error);
      }
      this.closeList();
    },
    closeList() {
      this.isOpen = false;
    },
    openList() {
      this.isOpen = true;
    },
    applyResultByPath({ specification, result, path }) {
      const definition = get(specification, path);
      if (isFunction(definition)) {
        return definition(result);
      }
      return definition;
    },
    getResultsCount() {
      const indices = map(this.config, 'algolia.index');
      this.hasResults = max(map(indices, index => size(this.$data[index])));
    },
  },
};
</script>
<template>
  <section class="OmnisearchComponent m-2">
    <ui-text-input
      v-click-outside="closeList"
      :value="textInput"
      :icon-left="ICONS.search"
      name="query"
      placeholder="Search Backoffice"
      @click="openList"
      @focus="openList"
      @keyup.esc="closeList"
      @changevalue="event => textInput = event.detail"
    />
    <ul
      v-show="isOpen"
      class="OmnisearchComponent__list mt-4 position-absolute emobg-background-color-white
              shadow-m emobg-border-radius-small
              overflow-auto"
      @mouseleave="closeList"
    >
      <template v-if="hasResults">
        <template v-for="specification in config">
          <li
            v-for="(result, index) in $data[specification.algolia.index]"
            :key="`${specification.algolia.index}-${index}`"
            class="cursor-pointer emobg-background-color-ground-lightest-hover emobg-focus-background-color-ground-lightest p-1"
          >
            <a
              :href="getHref({ specification, result })"
              class="d-flex align-items-center text-decoration-none emobg-color-inherit"
              @click="navigate(specification, result, $event)"
            >
              <article class="emobg-font-weight-semibold d-inline-flex align-items-center pill emobg-background-color-ground-lighter px-2 py-1">
                <ui-icon
                  :icon="specification.badge.icon"
                  :size="ICONS_SIZES.small"
                  class="emobg-font-weight-semibold"
                />
                <p class="m-1">
                  {{ specification.badge.text }}
                </p>
              </article>
              <article
                class="px-2 ellipsis"
                :class="applyResultByPath({result, specification, path: 'primary.class'})"
              >
                {{ specification.primary.text(result) }}
              </article>
              <article
                v-if="specification.secondary"
                class="px-2 ellipsis"
                :class="applyResultByPath({result, specification, path: 'secondary.class'})"
              >
                {{ specification.secondary.text(result) }}
              </article>
            </a>
          </li>
        </template>
      </template>
      <template v-else>
        <li class="emobg-background-color-ground-lightest-hover emobg-focus-background-color-ground-lightest px-2 py-3">
          No results
        </li>
      </template>
    </ul>
  </section>
</template>
